import React, { useCallback, useEffect, useState } from 'react'
import { getConfigValue } from '../../constants/config'
import { checkPaymentValidation } from '../../api/corporate/currentTask.api'
import { LbToast } from '../SimpleComponents/LBToasts'
import * as i18n from 'i18next'
import Icon from '../SimpleComponents/Icon'
import { LbLoader } from '../SimpleComponents/LBLoader'
import { isAuthyType } from '../../helpers/auth-types.helper'

let validationTimer = null

const AuthorizationButtons = props => {
  let pollingInterval = getConfigValue('validation_polling_interval')

  let {
    isCorporate,
    saveTransfer,
    saveButton,
    cancelButton,
    loading,
    isDisabled,
    authLaterButton,
    submitButton,
    submitHandler,
    validationId,
    twoFactorType,
    resendTwoFactorCode,
    fromPage,
    noSubmitButton
  } = props

  let [isActiveSubmitButton, setIsActiveSubmitButton] = useState(false)
  let [startOver, setStartOver] = useState(false)
  let [indicator, setIndicator] = useState(false)

  useEffect(() => {
    if (!twoFactorType || twoFactorType === 'None' || twoFactorType === 'Otp')
      setIsActiveSubmitButton(true)
    if ((validationId && isAuthyType(twoFactorType)) || startOver) {
      setIsActiveSubmitButton(false)
      clearInterval(validationTimer)
      validationTimer = setInterval(checkOperationValidity, pollingInterval)
      setIndicator(false)
    }

    return () => {
      clearInterval(validationTimer)
    }
  }, [validationId, twoFactorType, startOver])

  useEffect(() => {
    if (
      [
        'tariffPackages',
        'saveMoreDeposit',
        'currentTasks',
        'multiTask',
        'standingOrder',
        'RiskCategory'].includes(
        fromPage
      ) === false &&
      validationId &&
      isAuthyType(twoFactorType)
    )
      LbToast('warning', i18n.t('Transfers.PushNotificationSent'), {
        autoClose: 20000,
        closeButton: true,
        hideProgressBar: true
      })
  }, [validationId])

  useEffect(() => {
    if (isActiveSubmitButton) clearInterval(validationTimer)
    if (isActiveSubmitButton && noSubmitButton) submitHandler()
  }, [isActiveSubmitButton])

  const resendHandler = () => {
    resendTwoFactorCode()
    if (indicator) {
      if (isAuthyType(twoFactorType)) setStartOver(true)
      setIndicator(false)
    }
  }

  const checkOperationValidity = useCallback(() => {
    checkPaymentValidation(validationId).then(
      res => {
        setIsActiveSubmitButton(res.status && ['approved'].includes(res.status))
        if (res.status === 'denied') {
          clearInterval(validationTimer)
          setIndicator(true)
        }
        setStartOver(false)
      },
      err => {}
    )
  }, [validationId])

  return (
    <div className="flex-end flex-wrap mw-470">
      {isAuthyType(twoFactorType) && !isActiveSubmitButton && validationId && (
        <div className="flex-aligned lb-newsms-code mr-10 ml-auto mt-20">
          <i className="icn icn-refresh mr-10">
            <Icon name="icn-refresh" viewBox="0 0 19.286 19.286" />
          </i>
          <input
            id="resend-TranferConfirmation"
            type="submit"
            className="lb-btn-newcode lb-btn"
            value={i18n.t('Common.NewSmsCode')}
            onClick={resendHandler}
            disabled={loading}
          />
        </div>
      )}
      <div className="flex">
        {cancelButton && (
          <input
            id="cancel-TranferConfirmation"
            type="button"
            className="lb-btn btn-border btn-white btn-wide-35 mr-10"
            value={cancelButton.label}
            onClick={cancelButton.handler}
            disabled={loading}
          />
        )}
        {isCorporate && saveButton && (
          <input
            id="save-TranferConfirmation"
            type="submit"
            className="lb-btn btn-border btn-white btn-wide-35 mr-10"
            value={saveButton.label}
            onClick={saveTransfer}
            disabled={loading || isDisabled}
          />
        )}
        {authLaterButton && (
          <input
            id="auth-TranferConfirmation"
            type="submit"
            className="lb-btn btn-border btn-white btn-wide-35 mr-10"
            value={authLaterButton.label}
            onClick={authLaterButton.handler}
            disabled={loading}
          />
        )}
        {!noSubmitButton && submitButton && (
          <div className="po-r">
            <input
              id="submit-TranferConfirmation"
              type="button"
              className="lb-btn btn-border btn-white btn-wide-35 lb-btn-new"
              value={
                validationId && !isActiveSubmitButton && !indicator
                  ? ''
                  : submitButton.label
              }
              onClick={submitHandler}
              disabled={loading || isDisabled || !isActiveSubmitButton}
            />

            {validationId && !isActiveSubmitButton && !indicator && (
              <div className="loader-authoriz">
                <LbLoader isLoading size="xxs" inCenter />
              </div>
            )}
          </div>
        )}
      </div>

      {indicator && (
        <div className={'w-100 text-right mt-10 is-red fnt-low-m fnt-12'}>
          <i className="icn icn-warning mr-5 is-red pt-5">
            <Icon name="icn-warning-new2" view="0 0 26.864 23.74" />
          </i>
          <span className="flex-1">
            {i18n.t('Transfers.PushNotificationCanceledMessage')}
          </span>
        </div>
      )}
    </div>
  )
}
export default AuthorizationButtons
